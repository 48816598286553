<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels
          style="width: 800px; border: 0px; margin: 0 auto"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-6' : 'pa-7'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <div>
                <span style="font-size: 16px; font-weight: bold">
                  ขอรับรองว่า ฉันไม่ได้ซื้อลอตเตอรี่ในสถานศึกษา และ
                  ปัจจุบันฉันมีอายุครบ 20 ปี บริบูรณ์
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              ฉัน ในฐานะผู้ซื้อลอตเตอรี่ ตกลงและทราบดีว่า.
              www.เลขสวยลอตเตอรี่.comไม่จำหน่ายลอตเตอรี่ในสถานศึกษา
              และให้กับบุคคลที่อายุต่ำกว่า 20 ปี ทางwww.เลขสวยลอตเตอรี่.com
              ขอสงวนสิทธิ์ในการดำเนินคดีตามกฎหมายสูงสุด ทั้งทางแพ่งและอาญา
              กับผู้ที่มาซื้อลอตเตอรี่กับwww.เลขสวยลอตเตอรี่.com
              โดยมีอายุต่ำกว่า 20 ปีบริบูรณ์ หรือซื้อในสถานศึกษา
              <br />
              <br />
              ฉันตกลงยินยอม และยอมรับเงื่อนไขต่างๆ
              ที่ทางwww.เลขสวยลอตเตอรี่.comกำหนดขึ้น
              ทั้งในปัจจุบันและหรือในอนาคต เพื่อตรวจสอบอายุผู้ซื้อลอตเตอรี่
              ว่าไม่ต่ำกว่า 20 ปีบริบูรณ์
              <br />
              <br />
              www.เลขสวยลอตเตอรี่.comขอสงวนสิทธิ์ในการขอตรวจสอบอายุผู้ซื้อ
              และฉันตกลงยอมจ่ายค่าปรับให้กับทางwww.เลขสวยลอตเตอรี่.com เป็นเงิน
              10,000 (หนึ่งหมื่นบาทถ้วน) ต่อการซื้อลอตเตอรี่ 1 ใบ
              ในกรณีที่www.เลขสวยลอตเตอรี่.comตรวจพบว่า
              ฉันซื้อลอตเตอรี่ในสถานศึกษา และหรืออายุฉันยังไม่ถึง 20 ปีบริบูรณ์
              <br />
              ฉันตกลงและทราบดีว่า ทาง www.เลขสวยลอตเตอรี่.com
              ขอสงวนสิทธิ์ในการไม่โอนเงินรางวัลในกรณีผู้ซื้อถูกรางวัล
              และจะไม่คืนเงินค่าซื้อลอตเตอรี่ให้กับผู้ซื้อลอตเตอรี่.
              ในกรณีที่www.เลขสวยลอตเตอรี่.comตรวจพบว่า ผู้ซื้อมีอายุต่ำกว่า 20
              ปีบริบูรณ์ **
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" class="mt-n10">
        <v-expansion-panels
          style="width: 800px; border: 0px; margin: 0 auto"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-6' : 'pa-7'"
        >
          <v-expansion-panel>
            <v-expansion-panel-header class="text-center">
              <div>
                <span style="font-size: 16px; font-weight: bold">
                  ข้าพเจ้าเข้าใจดีว่า ผู้ให้บริการ จะเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้าเพื่อวัตถุประสงค์ในการให้บริการตามสัญญานี้
                  การวิเคราะห์ข้อมูลเพื่อวางแผนทางการตลาดการนำเสนอสินค้าและบริการอื่นๆ
                  ของผู้ให้บริการแก่ข้าพเจ้า รวมถึงวัตถุประสงค์อื่นๆ
                  ตามที่ผู้ให้บริการเห็นสมควร
                </span>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              ข้อกำหนดและเงื่อนไขฉบับนี้ ถือเป็นข้อตกลงระหว่าง
              www.เลขสวยลอตเตอรี่.com กับผู้ซื้อหรือผู้รับบริการ
              <br />
              <br />
              1. “ผู้ซื้อ” หมายถึง ผู้ขอใช้บริการ ผู้ใช้บริการ สมาชิก
              ตลอดจนผู้มีส่วนเกี่ยวข้อง
              <br />
              <br />
              2. “บริการ” หมายถึง
              บริการของผู้ให้บริการภายใต้ข้อกำหนดและเงื่อนไขฉบับนี้
              <br />
              <br />
              3.“ผู้ให้บริการ” หมายถึง www.เลขสวยลอตเตอรี่.com /
              เลขสวยล็อตเตอรี่
              <br />
              <br />
              4. “บริษัทฯ” หมายถึง www.เลขสวยลอตเตอรี่.com / เลขสวยล็อตเตอรี่
              <br />
              <br />
              5. “สลากกินแบ่งรัฐบาล” หมายถึง
              สลากกินแบ่งรัฐบาลที่ออกโดยสำนักงานสลากกินแบ่งรัฐบาลทุกประเภท
              <br />
              <br />
              6. “แพลตฟอร์ม” หมายถึง “https://www.เลขสวยลอตเตอรี่.com” หรือ “
              https://เลขสวยลอตเตอรี่.com ” หรือ
              “แอปพลิเคชันwww.เลขสวยลอตเตอรี่.com” ซึ่งเป็นบริษัทฯ
              ผู้ให้บริการจำหน่าย รับจัดเก็บ รับฝาก
              รับขึ้นเงินสลากกินแบ่งรัฐบาลตลอดจนประกอบกิจการอื่น ๆ
              ตามวัตถุประสงค์ของบริษัทฯ
              <br />
              <br />
              7. ผู้ซื้อยินยอมให้บริษัทฯ จัดเก็บข้อมูลส่วนบุคคล (Personal Data)
              ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคลพ.ศ.2562 PDPA (Personal
              Data Protection Act B.E. 2562 (2019)) เพื่อมีไว้ใช้และเปิดเผย
              ซึ่งข้อมูลส่วนบุคคล ได้แก่ชื่อ-นามสกุล หรือชื่อเล่น /
              เลขประจำตัวประชาชน, เลขหนังสือเดินทาง, เลขบัตรประกันสังคม,
              เลขใบอนุญาตขับขี่,เลขประจำตัวผู้เสียภาษี, เลขบัญชีธนาคาร,
              เลขบัตรเครดิต (การเก็บเป็นภาพสำเนาบัตรประชาชนหรือสำเนาบัตรอื่น
              ๆที่มีข้อมูลส่วนบุคคลที่กล่าวมาย่อมสามารถใช้ระบุตัวบุคคลได้โดยตัวมันเอง
              จึงถือเป็นข้อมูลส่วนบุคคล) / ที่อยู่, อีเมล์,เลขโทรศัพท์ /
              ข้อมูลอุปกรณ์หรือเครื่องมือ เช่น IP address, MAC address, Cookie
              ID / ข้อมูลระบุทรัพย์สินของบุคคลเช่น ทะเบียนรถยนต์, โฉนดที่ดิน /
              ข้อมูลที่สามารถเชื่อมโยงไปยังข้อมูลข้างต้นได้ เช่น
              วันเกิดและสถานที่เกิด,เชื้อชาติ,สัญชาติ, น้ำหนัก, ส่วนสูง,
              ข้อมูลตำแหน่งที่อยู่ (location), ข้อมูลการแพทย์, ข้อมูลการศึกษา,
              ข้อมูลทางการเงิน,ข้อมูลการจ้างงาน /
              ข้อมูลหมายเลขอ้างอิงที่เก็บไว้ในไมโครฟิล์ม
              แม้ไม่สามารถระบุไปถึงตัวบุคคลได้แต่หากใช้ร่วมกับระบบดัชนีข้อมูลอีกระบบหนึ่งก็จะสามารถระบุไปถึงตัวบุคคลได้
              /ข้อมูลการประเมินผลการทำงานหรือความเห็นของนายจ้างต่อการทำงานของลูกจ้าง
              / ข้อมูลบันทึกต่าง ๆที่ใช้ติดตามตรวจสอบกิจกรรมต่าง ๆ ของบุคคล เช่น
              log file
              /ข้อมูลที่สามารถใช้ในการค้นหาข้อมูลส่วนบุคคลอื่นในอินเทอร์เน็ต
              ตลอดจนข้อมูลส่วนบุคคลที่มีความละเอียดอ่อน(Sensitive Personal Data)
              ได้แก่ ข้อมูล เชื้อชาติ, เผ่าพันธุ์, ความคิดเห็นทางการเมือง,
              ความเชื่อในลัทธิศาสนาหรือปรัชญา, พฤติกรรมทางเพศ, ประวัติอาชญากรรม,
              ข้อมูลสุขภาพ ความพิการ หรือข้อมูลสุขภาพจิต,ข้อมูลสหภาพแรงงาน,
              ข้อมูลพันธุกรรม, ข้อมูลชีวภาพ, ข้อมูลทางชีวมิติ (Biometric) เช่น
              รูปภาพใบหน้า, ลายนิ้วมือ,ฟิล์มเอกซเรย์, ข้อมูลสแกนม่านตา,
              ข้อมูลอัตลักษณ์เสียง, ข้อมูลพันธุกรรม
              เป็นต้นและข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลในทำนองเดียวกันตามที่คณะกรรมการประกาศกำหนด
              <br />
              <br />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>

      <v-col
        cols="12"
        class="text-center mt-n10 mb-n6 pa-10"
        v-if="userData.user.socialId !== ''"
      >
        <v-btn
          class="custom-btn"
          style="background-color: #07bc0c"
          @click="goToLine()"
        >
          <span style="font-size: 16px; font-weight: bold; color: white"
            >รับการแจ้งเตือนผ่าน LINE
          </span>
          &emsp;
          <v-img
            src="../assets/logo/Line-Logo.png"
            max-width="20px"
            height="auto"
          ></v-img
        ></v-btn>
      </v-col>

      <v-col cols="12" class="mt-n8 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-2"
          ><v-card-title
            ><span style="color: #4ca3ad">ข้อมูลส่วนตัวผู้ใช้</span>&emsp;
            <!-- <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn> -->
          </v-card-title>
          <v-row class="pa-6">
            <v-col cols="12" class="text-center">
              <img
                src="../assets/logo/Logo1.jpg"
                alt="Profile"
                class="responsive-img"
              />
            </v-col>
            <!-- <v-col cols="12" class="text-center mt-n5">
              <v-btn color="#2e2e2e"
                ><span style="color: #fff">เปลี่ยนรูปโปรไฟล์</span>
              </v-btn>
            </v-col> -->
            <!-- <v-col cols="12" class="text-center mt-n5">
              <span>เลขที่สมาชิก: TEST</span>
            </v-col> -->

            <v-col cols="12">
              <span style="color: #4ca3ad">ชื่อ-นามสกุล </span>&emsp;
              <v-btn small color="#2e2e2e" @click="editName()"
                ><v-icon size="16px" style="color: #fff"
                  >mdi-pencil-outline</v-icon
                >
                <span style="color: #fff">แก้ไขชื่อ - นามสกุล</span>
              </v-btn>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อ <br /></span>
              <span>{{ userData.user ? userData.user.firstName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">นามสกุล <br /></span>
              <span>{{ userData.user ? userData.user.lastName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">เบอร์โทรศัพท์ <br /></span>
              <span>{{ userData.user ? userData.user.telNumber : "" }}</span>
            </v-col>
            <!-- <v-col cols="6" class="">
              <span style="font-weight: bold">อีเมล์ <br /></span>
              <span>{{ userData.user.email ? userData.user.email : "-" }}</span>
            </v-col> -->
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">ลิ้งค์ชวนเพื่อน</span>
          </v-card-title>
          <v-row class="pa-6">
            <span>link aff</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff">mdi-copy-outline</v-icon>
              <span style="color: #fff">คัดลอก</span>
            </v-btn>
          </v-row>
          <v-card-title
            ><span style="color: #4ca3ad">รายได้ชวนเพื่อน</span>
          </v-card-title>
          <v-card class="text-center aff-card ma-2">ยอดสะสม 0 บาท</v-card>
        </v-card>
      </v-col>
      <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">อัพเดทสถานะคำสั่งซื้อ</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-radio-group v-model="receiveNews" column>
              <v-radio
                label="รับผ่าน SMS"
                value="sms"
                :disabled="isUpdate"
              ></v-radio>
              <v-radio
                label="รับผ่าน Line"
                value="line"
                :disabled="isUpdate"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-card>
      </v-col> -->
      <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad"
              >ข้อมูลบัญชี
              <span style="font-weight: 600"><u>(รับเงินรางวัล)</u></span></span
            >&emsp;
            <v-btn small color="#2e2e2e" @click="addBankAccount()"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อบัญชี <br /></span>
              <span>{{ userData.user ? userData.user.accountName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">ชื่อธนาคาร <br /></span>
              <span>{{ userData.user ? userData.user.bankName : "" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">เลขที่บัญชี <br /></span>
              <span>{{ userData.user ? userData.user.bankNo : "" }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" class="mt-n14 pa-10">
        <v-card style="width: 800px; border: 0px; margin: 0 auto" class="pa-4"
          ><v-card-title
            ><span style="color: #4ca3ad">ข้อมูลที่อยู่</span>&emsp;
            <v-btn small color="#2e2e2e"
              ><v-icon size="16px" style="color: #fff"
                >mdi-pencil-outline</v-icon
              >
              <span style="color: #fff">แก้ไข</span>
            </v-btn>
          </v-card-title>
          <v-row class="pa-6 mt-n10">
            <v-col cols="6" class="">
              <span style="font-weight: bold">ที่อยู่ <br /></span>
              <span>{{ userData.address ? userData.address : "-" }}</span>
            </v-col>
            <v-col cols="6" class=""> </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">จังหวัด <br /></span>
              <span>{{ userData.province ? userData.province : "-" }}</span>
            </v-col>
            <v-col cols="6" class="">
              <span style="font-weight: bold">รหัสไปรษณีย์ <br /></span>
              <span>{{ userData.zipcode ? userData.zipcode : "-" }}</span>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
    </v-row>
    <DialogBankAccount
      :dialog="dialogBankAccount"
      @closeDialogBankAccount="closeDialogBankAccount"
    />
    <DialogEditName
      :dialog="dialogEditName"
      @closeDialogEditName="closeDialogEditName"
    />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogBankAccount from "./Dialog/DialogBankAccount.vue";
import DialogEditName from "./Dialog/DialogEditName.vue";

export default {
  components: {
    DialogBankAccount,
    DialogEditName,
  },

  data: () => ({
    dialogBankAccount: false,
    dialogEditName: false,
    checkbox1: true,
    checkbox2: true,

    receiveNews: null,
    isUpdate: true,
    userData: [],
  }),

  created() {
    const encodedUserLogin = localStorage.getItem("LEKSUAYUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);

      this.receiveNews = this.userData.receiveNews;
    }
    console.log("this.userData", this.userData);
  },
  methods: {
    goToLine() {
      window.open("https://line.me/ti/p/~@535gzsdf");
    },
    async closeDialogBankAccount() {
      this.dialogBankAccount = false;
      this.$router.go(0);
    },
    async closeDialogEditName() {
      this.dialogEditName = false;
      this.$router.go(0);
    },

    addBankAccount() {
      this.dialogBankAccount = true;
    },
    editName() {
      this.dialogEditName = true;
    },
  },
};
</script>

<style scoped>
.single-line-row {
  display: flex;
  align-items: center;
}

.responsive-img {
  width: 120px;
  max-width: 50%;
  height: 120px;
  border: 1px solid #008080;
  border-radius: 50%;
}

.aff-card {
  margin-top: 15px;
  padding: 7px;
  background: rgb(109, 119, 141);
  color: white;
  font-weight: bold;
  border-radius: 5px;
  cursor: no-drop;
  flex-grow: 1;
}
</style>

